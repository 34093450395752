import axios from 'axios'

const { REACT_APP_ENV } = process.env

const API_ENDPOINTS = {
  DEMO: 'http://usbackend-demo.us-east-1.elasticbeanstalk.com/v1',
  QA: 'https://robot-qa-api-jhf3t.ondigitalocean.app/v1',
  PROD: 'https://api.unidadseguros.cl/v1'
}

const axiosInstance = axios.create({
  baseURL: API_ENDPOINTS[REACT_APP_ENV] ?? 'http://localhost:8000/v1',
  mode: 'no-cors'
})

export default axiosInstance
